<template>
  <validation-observer
    ref="resetPasswordForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form @submit.prevent="handleSubmit($emit('reset-password', password, oldPassword))">
      <validation-provider
        v-slot="{ errors }"
        rules="required|min:6|confirmed:oldPassword"
        name="password"
      >
        <b-field
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            v-model="password"
            password-reveal
            type="password"
            placeholder="New Password"
            icon="lock"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        rules="required|min:6"
        name="confirm password"
        vid="oldPassword"
      >
        <b-field
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            v-model="oldPassword"
            password-reveal
            type="password"
            placeholder="Confirm Password"
            icon="lock"
          />
        </b-field>
      </validation-provider>

      <b-field>
        <b-button
          :disabled="invalid"
          native-type="submit"
          type="is-info"
          expanded
        >
          reset password
        </b-button>
      </b-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {

  name: 'ResetPasswordForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    password: '',
    oldPassword: '',
  }),

};
</script>

<style lang="css" scoped></style>
