<template>
  <authentication-layout>
    <template #content>
      <div class="content">
        <p class="has-text-white">
          Hi, you are being redirected to your business portal.
        </p>
      </div>
    </template>
  </authentication-layout>
</template>

<script>
export default {
  name: 'Redirect',

  components: {
    AuthenticationLayout: () => import('../../layouts/AuthenticationLayout.vue'),
  },

  async mounted() {
    try {
      localStorage.clear();
      localStorage.setItem('access_token', this.$route.params.token);
      localStorage.setItem('email', this.$route.params.email);
      await this.$store.dispatch('Authentication/authorizeRedirect');
      await this.$router.push('/profile');
    } catch (error) {
      await this.$router.push('/');
    }
  },
};
</script>

<style>

</style>
