<template>
  <validation-observer
    ref="loginForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form @submit.prevent="handleSubmit($emit('login-user'))">
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="email"
      >
        <b-field
          label-for="email"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            id="email"
            v-model="username"
            placeholder="Email"
            icon="account"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="password"
      >
        <b-field
          label-for="password"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            id="password"
            v-model="password"
            placeholder="Password"
            password-reveal
            type="password"
            icon="key"
          />
        </b-field>
      </validation-provider>

      <b-field>
        <b-button
          native-type="submit"
          :disabled="invalid"
          type="is-info"
          value="sign in"
          aria-label="sign in"
          expanded
        >
          sign in
        </b-button>
      </b-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {

  name: 'LoginForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    username: {
      get() {
        return this.$store.state.Authentication.credentials.username;
      },

      set(value) {
        return this.$store.commit('Authentication/SET_CREDENTIALS_USERNAME', value);
      },
    },

    password: {
      get() {
        return this.$store.state.Authentication.credentials.password;
      },

      set(value) {
        return this.$store.commit('Authentication/SET_CREDENTIALS_PASSWORD', value);
      },
    },
  },
};
</script>

<style lang="css" scoped></style>
