<template>
  <authentication-layout>
    <template #content>
      <div class="card">
        <!-- loading component -->
        <b-loading
          v-model="isLoading"
          :is-full-page="false"
        />

        <!-- error message component -->
        <b-message
          v-model="isInvalid"
          type="is-warning"
          has-icon
        >
          {{ message }}
        </b-message>

        <!-- reset password form component -->
        <reset-password-form @reset-password="resetPassword" />
      </div>
    </template>
  </authentication-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { ResetPasswordForm } from '@/components/Authentication';

export default {

  name: 'Login',

  components: {
    AuthenticationLayout: () => import('../../layouts/AuthenticationLayout.vue'),
    ResetPasswordForm,
  },

  data: () => ({
    message: '',
    isInvalid: false,
  }),

  computed: mapGetters({
    isLoading: 'Authentication/getLoading',
  }),

  created() {
    this.$store.dispatch('Authentication/logout');
  },

  methods: {
    /**
     * resetPassword
     *
     * Reset an existing user's password
     *
     * @param { String } password - user's new password
     * @param { String } oldPassword - user's new password confirmation
     */
    async resetPassword(password, oldPassword) {
      try {
        this.isInvalid = false;
        await this.$store.dispatch('Authentication/resetPassword', {
          password,
          oldPassword,
          token: this.$route.params.token,
        });
        await this.$router.push('/');
      } catch (error) {
        this.message = error.message;
        this.isInvalid = true;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
