<template>
  <authentication-layout>
    <template #content>
      <div class="card">
        <!-- error message component -->
        <b-message
          v-model="isInvalid"
          type="is-warning"
          has-icon
        >
          {{ message }}
        </b-message>

        <!-- loading component -->
        <b-loading
          v-model="isLoading"
          :is-full-page="false"
        />

        <login-form @login-user="loginUser" />

        <div class="level is-spaced-top">
          <div class="level-left" />

          <div class="level-right">
            <div class="level-item">
              <router-link to="/forgot-password">
                <small>Forgot Password?</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </authentication-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import getConfig from '@/plugins/config';

import { LoginForm } from '@/components/Authentication';

export default {
  name: 'Login',

  components: {
    AuthenticationLayout: () => import('../../layouts/AuthenticationLayout.vue'),
    LoginForm,
  },

  data: () => ({
    message: '',
    isInvalid: false,
  }),

  computed: mapGetters({
    isLoading: 'Authentication/getLoading',
    credentials: 'Authentication/getCredentials',
  }),

  beforeCreate() {
    // localStorage.clear();
    if (localStorage.getItem('access_token')) {
      this.$router.push('/profile');
    }
  },

  destroyed() {
    this.$store.commit('Authentication/CLEAR_CREDENTIALS');
    window.location.reload();
  },

  methods: {
    async loginUser() {
      const VUE_APP_GRANT_TYPE = getConfig('VUE_APP_GRANT_TYPE');

      const payload = new FormData();
      payload.append('grant_type', VUE_APP_GRANT_TYPE);
      payload.append('username', this.credentials.username.trim());
      payload.append('password', this.credentials.password.trim());
      try {
        this.isInvalid = false;
        const { username } = this.credentials;
        localStorage.setItem('username', username);
        await this.$store.dispatch('Authentication/login', { payload, username });
        await this.$router.push('/profile');
        window.reload();
      } catch (error) {
        this.message = 'Invalid Email or Password';
        this.isInvalid = true;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
