<template>
  <authentication-layout>
    <template #content>
      <div class="card">
        <!-- loading component -->
        <b-loading
          v-model="isLoading"
          :is-full-page="false"
        />

        <!-- error message component -->
        <b-message
          v-model="isInvalid"
          type="is-warning"
          has-icon
        >
          {{ message }}
        </b-message>

        <!-- forgot password form component -->
        <forgot-password-form @send-password-reset-link="sendPasswordResetLink" />
      </div>
    </template>
  </authentication-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { ForgotPasswordForm } from '@/components/Authentication';

export default {

  name: 'Login',

  components: {
    AuthenticationLayout: () => import('../../layouts/AuthenticationLayout.vue'),
    ForgotPasswordForm,
  },

  data: () => ({
    message: '',
    isInvalid: false,
  }),

  computed: mapGetters({
    isLoading: 'Authentication/getLoading',
  }),

  methods: {
    async sendPasswordResetLink(email) {
      try {
        this.isInvalid = false;
        await this.$store.dispatch('Authentication/sendPasswordResetLink', email);
        await this.$router.push('/');
      } catch (error) {
        this.message = 'Sorry, email doesn\'t exist';
        this.isInvalid = true;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
